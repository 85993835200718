<template>
    <div class="row">
        <div class="col-md-12">
            <button @click="$router.push('blc')" class="btn btn-primary">Add</button>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bl in Bls" :key="bl.key">
                        <td>{{ bl.name }}</td>
                        <td>{{ bl.notes }}</td>
                        <td>
                            <router-link :to="{name: 'BLEdit', params: { id: bl.key }}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteBL(bl.key)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Bls: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('bls').orderBy('name').onSnapshot((snapshotChange) => {
                this.Bls = [];
                snapshotChange.forEach((doc) => {
                    if(this.currentTenant == doc.data().tenant && doc.data().deleted !== true) {
                    this.Bls.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                    })
                    }
                });
            })
            },
            deleteBL(id){
              if (window.confirm("Do you really want to delete?")) {
                this.newCem = this.Bls.find(x => x.key === id)
                console.log(this.newCem)
                this.newCem.deleted = true;
                if(this.newCem.notes == null) {
                    this.newCem.notes = ""
                }
                db.collection("bls").doc(id).update(this.newCem).then(() => {
                    console.log("Cemetery deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>